import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link, useParams } from "react-router-dom";
import { PiUploadSimpleBold } from "react-icons/pi";
import { RxEnter } from "react-icons/rx";

const KajiPDF = () => {
  const [modalDimension, setModalDimension] = useState("");
  const [modalSubDimension, setModalSubDimension] = useState("");
  const [modalParameter, setModalParameter] = useState("");
  const [isAddKeywordModalOpen, setIsAddKeywordModalOpen] = useState(false);
  const { projectId, productId, dimension } = useParams();
  const [dimensionFolders, setDimensionFolders] = useState([]);
  const [modalSubDimensions, setModalSubDimensions] = useState([]);
  const [modalSubDimensionParameters, setModalSubDimensionParameters] = useState({});
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [subDimensions, setSubDimensions] = useState([]);
  const [subDimensionParameters, setSubDimensionParameters] = useState({});
  const [currentDimensionIndex, setCurrentDimensionIndex] = useState(0);
  const [sidebarHidden, setSidebarHidden] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(true);
  const [keywordLists, setKeywordLists] = useState({});
  const [selectedDimension, setSelectedDimension] = useState('');
  const [selectedSubDimension, setSelectedSubDimension] = useState('');
  const [selectedParameter, setSelectedParameter] = useState('');
  const [uploadedKeywords, setUploadedKeywords] = useState([]);
  const [keyword, setKeyword] = useState('');



  const openModal = () => {
    setIsAddKeywordModalOpen(true);
  };

  useEffect(() => {
    fetchDimensionFolders();
  }, []);

  const fetchDimensionFolders = async () => {
    try {
      const response = await axios.get('https://api1.rwiconsulting.tech/dimensions');
      setDimensionFolders(response.data.folders);
    } catch (error) {
      console.error('Error fetching dimension folders:', error);
    }
  };

  const handleDimensionChange = async (e) => {
    const selectedDimension = e.target.value;
    setModalDimension(selectedDimension);
    if (selectedDimension) {
      try {
        const response = await axios.get(`https://api1.rwiconsulting.tech/dimensions/${selectedDimension}/subdimensions`);
        setModalSubDimensions(response.data.subDimensions);
        setModalSubDimension('');
        setModalParameter('');
        setModalSubDimensionParameters({});
      } catch (error) {
        console.error('Error fetching subdimensions:', error);
      }
    } else {
      setModalSubDimensions([]);
      setModalSubDimension('');
      setModalParameter('');
    }
  };

  const handleSubDimensionChange = async (e) => {
    const subDimension = e.target.value;
    setModalSubDimension(subDimension);
    if (subDimension) {
      try {
        const response = await axios.get(`https://api1.rwiconsulting.tech/dimensions/${modalDimension}/${subDimension}/parameters`);
        setModalSubDimensionParameters({ [subDimension]: response.data.parameters });
      } catch (error) {
        console.error('Error fetching parameters:', error);
      }
    } else {
      setModalParameter('');
    }
  };

  const fetchUploadedKeywords = async () => {
    try {
      const response = await axios.get(`https://api1.rwiconsulting.tech/keywords/${selectedDimension}/${selectedSubDimension}/${selectedParameter}`);
      if (!response.ok) {
        throw new Error('Failed to fetch keywords');
      }
      setUploadedKeywords(response.data);
    } catch (error) {
      console.error('Error fetching keywords:', error);
    }
  };

  const handleAddUploadedKeywords = async () => {
    const selectedKeywords = uploadedKeywords.filter(keyword => keyword.checked);
    if (selectedKeywords.length === 0) {
      console.log('No keywords selected');
      return;
    }
  
    try {
      const newKeywords = [];
  
      if (!modalDimension) {
        for (const dimension of dimensionFolders) {
          const subDimensionsResponse = await axios.get(`https://api1.rwiconsulting.tech/dimensions/${dimension}/subdimensions`);
          const subDimensions = subDimensionsResponse.data.subDimensions;
  
          for (const subDim of subDimensions) {
            const parametersResponse = await axios.get(`https://api1.rwiconsulting.tech/dimensions/${dimension}/${subDim}/parameters`);
            const parameters = parametersResponse.data.parameters;
  
            for (const param of parameters) {
              await addKeywordsToInsert(projectId, dimension, subDim, param, selectedKeywords);
              newKeywords.push(...selectedKeywords.map(kw => ({ dimension, subDim, param, keyword: kw.keyword })));
            }
          }
        }
      } else if (modalDimension && !modalSubDimension) {
        const subDimensionsResponse = await axios.get(`https://api1.rwiconsulting.tech/dimensions/${modalDimension}/subdimensions`);
        const subDimensions = subDimensionsResponse.data.subDimensions;
  
        for (const subDim of subDimensions) {
          const parametersResponse = await axios.get(`https://api1.rwiconsulting.tech/dimensions/${modalDimension}/${subDim}/parameters`);
          const parameters = parametersResponse.data.parameters;
  
          for (const param of parameters) {
            await addKeywordsToInsert(projectId, modalDimension, subDim, param, selectedKeywords);
            newKeywords.push(...selectedKeywords.map(kw => ({ dimension: modalDimension, subDim, param, keyword: kw.keyword })));
          }
        }
      } else if (modalDimension && modalSubDimension && !modalParameter) {
        const parametersResponse = await axios.get(`https://api1.rwiconsulting.tech/dimensions/${modalDimension}/${modalSubDimension}/parameters`);
        const parameters = parametersResponse.data.parameters;
  
        for (const param of parameters) {
          await addKeywordsToInsert(projectId, modalDimension, modalSubDimension, param, selectedKeywords);
          newKeywords.push(...selectedKeywords.map(kw => ({ dimension: modalDimension, subDimension: modalSubDimension, param, keyword: kw.keyword })));
        }
      } else if (modalDimension && modalSubDimension && modalParameter) {
        await addKeywordsToInsert(projectId, modalDimension, modalSubDimension, modalParameter, selectedKeywords);
        newKeywords.push(...selectedKeywords.map(kw => ({ dimension: modalDimension, subDimension: modalSubDimension, param: modalParameter, keyword: kw.keyword })));
      }
  
      console.log('Keywords successfully added to Insert');
  
      // Update the state with the new keywords and avoid duplicates
      setKeywordsByParameter(prevState => {
        const updatedState = { ...prevState };
  
        newKeywords.forEach(({ dimension, subDim, param, keyword }) => {
          const key = `${dimension}/${subDim}/${param}`;
          const currentKeywords = updatedState[key] || [];
          if (!currentKeywords.some(kw => kw.keyword.toLowerCase() === keyword.toLowerCase())) {
            currentKeywords.push({ keyword });
          }
          updatedState[key] = currentKeywords;
        });
  
        return updatedState;
      });
  
      // Maintain the checked state of checkboxes
      setUploadedKeywords(prevKeywords => {
        return prevKeywords.map(keyword => {
          const selected = selectedKeywords.find(sel => sel.id === keyword.id);
          if (selected) {
            return { ...keyword, checked: true };
          }
          return keyword;
        });
      });
  
      // Close the modal
      setIsAddKeywordModalOpen(false);
    } catch (error) {
      console.error('Error adding keywords to Insert:', error);
    }
  };
  
  
  const addKeywordsToInsert = async (projectId, dimension, subDimension, parameter, keywords) => {
    try {
      const response = await axios.post('https://api1.rwiconsulting.tech/insert', {
        project_id: projectId,
        dimensi: dimension,
        subdimensi: subDimension,
        parameter: parameter,
        keywords: keywords
      });
  
      if (response.status !== 201) {
        throw new Error(`Failed to add keywords to Insert. Status code: ${response.status}`);
      }
    } catch (error) {
      console.error('Error adding keywords to Insert:', error);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    axios.get('https://api1.rwiconsulting.tech/dimensions')
      .then(response => {
        setDimensionFolders(response.data.folders);
        const index = response.data.folders.indexOf(dimension);
        setCurrentDimensionIndex(index);
      })
      .catch(error => {
        console.error('Error fetching dimension folders:', error);
      });
  }, [dimension]);


  const handleNextClick = () => {
    const nextIndex = (currentDimensionIndex + 1) % dimensionFolders.length;
    const nextDimension = dimensionFolders[nextIndex];
    navigate(`/projects/${projectId}/ProjectDetail/${productId}/kajiPDF/${nextDimension}`);
    setCurrentDimensionIndex(nextIndex);
  };

  const handlePreviousClick = () => {
    const previousIndex = (currentDimensionIndex - 1 + dimensionFolders.length) % dimensionFolders.length;
    const previousDimension = dimensionFolders[previousIndex];
    navigate(`/projects/${projectId}/ProjectDetail/${productId}/kajiPDF/${previousDimension}`);
    setCurrentDimensionIndex(previousIndex);
  };

  const [files, setFiles] = useState([]);
  const [searchResults, setSearchResults] = useState({});
  const [showFolderWarning, setShowFolderWarning] = useState(false);
  const [searched, setSearched] = useState(true);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false); // Variabel untuk modal hasil pencarian
  const [currentFolder, setCurrentFolder] = useState(0);

  const deleteKeyword = async (dimension, subDimension, parameter, keywordIndex) => {
    const keywordToDelete = keywordsByParameter[`${dimension}/${subDimension}/${parameter}`][keywordIndex]?.keyword;
    
    if (window.confirm("Are you sure you want to delete this keyword from all subdimensions and parameters within the selected dimension?")) {
      // Delete from all subdimensions and parameters within the selected dimension
      try {
        await axios.delete(`https://api1.rwiconsulting.tech/keywords/${projectId}/${dimension}/${subDimension}/${parameter}/${keywordToDelete}`, {
          params: {
            deleteFromAll: 'true'
          }
        });

        // Remove keyword from all subdimensions and parameters
        const updatedKeywords = { ...keywordsByParameter };
        Object.keys(updatedKeywords).forEach(key => {
          updatedKeywords[key] = updatedKeywords[key].filter(keyword => keyword.keyword !== keywordToDelete);
        });
        setKeywordsByParameter(updatedKeywords);
      } catch (error) {
        console.error('Error deleting keyword:', error);
      }
    } else {
      // Delete only from the specific subdimension and parameter
      try {
        await axios.delete(`https://api1.rwiconsulting.tech/keywords/${projectId}/${dimension}/${subDimension}/${parameter}/${keywordToDelete}`);

        // Remove keyword only from the specific subdimension and parameter
        const updatedKeywords = { ...keywordsByParameter };
        updatedKeywords[`${dimension}/${subDimension}/${parameter}`] = updatedKeywords[`${dimension}/${subDimension}/${parameter}`].filter((_, index) => index !== keywordIndex);
        setKeywordsByParameter(updatedKeywords);
      } catch (error) {
        console.error('Error deleting keyword:', error);
      }
    }
  };

  useEffect(() => {
    const fetchSubDimensions = async () => {
      try {
        const response = await axios.get(`https://api1.rwiconsulting.tech/dimensions/${dimension}/subdimensions`);
        setSubDimensions(response.data.subDimensions);
      } catch (error) {
        console.error('Error fetching subdimensions:', error);
      }
    };
    fetchSubDimensions();
  }, [dimension]);

  useEffect(() => {
    if (subDimensions.length > 0) {
      const fetchParameters = async () => {
        try {
          const subDimensionParams = {};
          const responses = await Promise.all(
            subDimensions.map((subDim) => axios.get(`https://api1.rwiconsulting.tech/dimensions/${dimension}/${subDim}/parameters`))
          );
          responses.forEach((response, index) => {
            const parameters = response.data.parameters;
            parameters.sort((a, b) => parseInt(a) - parseInt(b));
            subDimensionParams[subDimensions[index]] = parameters;
          });
          setSubDimensionParameters(subDimensionParams);
        } catch (error) {
          console.error('Error fetching parameters:', error);
        }
      };
      fetchParameters();
    }
  }, [dimension, subDimensions]);

  const exportKeywordsToTxt = (dimension, subDimension, parameter) => {
    const key = `${dimension}/${subDimension}/${parameter}`;
    if (!keywordsByParameter[key]) {
      return;
    }
    const keywords = keywordsByParameter[key].map(kw => kw.keyword).join('\n');
    const filename = `${parameter}.txt`;
    const blob = new Blob([keywords], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  



  useEffect(() => {
    if (selectedDimension && selectedSubDimension && selectedParameter) {
      fetchKeywords();
    }
  }, [selectedDimension, selectedSubDimension, selectedParameter]);

  const fetchKeywords = async () => {
    try {
      const response = await fetch(`https://api1.rwiconsulting.tech/keywords/${selectedDimension}/${selectedSubDimension}/${selectedParameter}`);
      if (!response.ok) {
        throw new Error('Failed to fetch keywords');
      }
      const keywords = await response.json();
      setUploadedKeywords(keywords.map(keyword => ({ ...keyword })));
    } catch (error) {
      console.error('Error fetching keywords:', error);
    }
  };

  const updateKeywordChecked = async (id, checked) => {
    try {
      const response = await fetch(`https://api1.rwiconsulting.tech/keywords/${id}/checked`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ checked }),
      });
      if (!response.ok) {
        throw new Error('Failed to update keyword checked status');
      }
      console.log('Keyword checked status successfully updated');
    } catch (error) {
      console.error('Error updating keyword checked status:', error);
    }
  };

  const handleDeleteKeyword = async (id) => {
    try {
      const response = await fetch(`https://api1.rwiconsulting.tech/keywords/${id}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error('Failed to delete keyword');
      }
      fetchKeywords();
    } catch (error) {
      console.error('Error deleting keyword:', error);
    }
  };

  const handleTxtFileUpload = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      const contents = e.target.result;
      const newKeywords = contents.split('\n').map(keyword => keyword.trim()).filter(keyword => keyword);

      const uniqueKeywords = newKeywords.filter((keyword, index, self) =>
        index === self.findIndex((k) => k.toLowerCase() === keyword.toLowerCase())
      );

      try {
        const response = await fetch(`https://api1.rwiconsulting.tech/keywords/${selectedDimension}/${selectedSubDimension}/${selectedParameter}`);
        if (!response.ok) {
          throw new Error('Failed to fetch existing keywords');
        }
        const existingKeywords = await response.json();

        const existingKeywordSet = new Set(existingKeywords.map(keyword => keyword.keyword.toLowerCase()));

        const newUniqueKeywords = uniqueKeywords.filter(keyword => !existingKeywordSet.has(keyword.toLowerCase()));

        if (newUniqueKeywords.length > 0) {
          const response = await fetch('https://api1.rwiconsulting.tech/keywords', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              dimensi: selectedDimension,
              subDimensi: selectedSubDimension,
              parameter: selectedParameter,
              keywords: newUniqueKeywords.map(keyword => ({ keyword, checked: true })),
            }),
          });

          if (!response.ok) {
            throw new Error('Failed to upload keywords');
          }

          console.log('Keywords successfully uploaded');
          fetchKeywords();
        } else {
          console.log('No new unique keywords to upload');
        }
      } catch (error) {
        console.error('Error uploading keywords:', error);
      }
    };

    reader.readAsText(file);

    // Reset the file input value
    event.target.value = '';
  };

  const handleEnterKey = async (e) => {
    if (e.key === 'Enter' && keyword.trim() !== '') {
      const newKeyword = keyword.trim();

      try {
        const response = await fetch(`https://api1.rwiconsulting.tech/keywords/${selectedDimension}/${selectedSubDimension}/${selectedParameter}`);
        if (!response.ok) {
          throw new Error('Failed to fetch existing keywords');
        }
        const existingKeywords = await response.json();

        const existingKeywordSet = new Set(existingKeywords.map(keyword => keyword.keyword.toLowerCase()));

        if (!existingKeywordSet.has(newKeyword.toLowerCase())) {
          const response = await fetch('https://api1.rwiconsulting.tech/keywords', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              dimensi: selectedDimension,
              subDimensi: selectedSubDimension,
              parameter: selectedParameter,
              keywords: [{ keyword: newKeyword, checked: true }],
            }),
          });

          if (!response.ok) {
            throw new Error('Failed to add keyword');
          }

          console.log('Keyword successfully added');
          fetchKeywords();
        } else {
          console.log('Keyword already exists');
        }
      } catch (error) {
        console.error('Error adding keyword:', error);
      }

      setKeyword('');
    }
  };

  const handleDeleteFile = async (folderPath, fileName) => {
    try {
      // Tampilkan dialog konfirmasi
      const confirmDelete = window.confirm(`Apakah Anda yakin ingin menghapus file ${fileName}?`);
      if (!confirmDelete) return; // Jika pengguna membatalkan, hentikan operasi penghapusan
  
      await axios.delete(`https://api1.rwiconsulting.tech/files/${projectId}?folder=${encodeURIComponent(folderPath)}&fileName=${fileName}`);
  
      // Panggil kembali fetchFiles untuk memperbarui state setelah operasi penghapusan selesai
      const fetchFiles = async () => {
        try {
          const parameters = Object.keys(subDimensionParameters).flatMap(subDimension =>
            subDimensionParameters[subDimension].map(parameter => `${dimension}/${subDimension}/${parameter}`)
          );
  
          const promises = parameters.map(parameter =>
            axios.get(`https://api1.rwiconsulting.tech/files/${projectId}?folder=docs/${parameter}`)
          );
  
          const responses = await Promise.all(promises);
  
          // Menyimpan file-file berdasarkan parameter
          const filesByParameter = {};
          responses.forEach((response, index) => {
            const parameter = parameters[index];
            filesByParameter[parameter] = response.data.files;
          });
          setFilesByParameter(filesByParameter);
        } catch (error) {
          console.error('Error fetching files:', error);
        }
      };
  
      fetchFiles();
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

    const toggleDropdown2 = () => {
    setIsOpen2(!isOpen2);
  };
  

  const [searching, setSearching] = useState(false);

  useEffect(() => {
    const fetchKeywords = async (dimension, subDimension, parameter) => {
      try {
        const response = await axios.get(`https://api1.rwiconsulting.tech/keywords/${projectId}/${dimension}/${subDimension}/${parameter}`);
        const data = response.data;
        setKeywordsByParameter(prevState => ({
          ...prevState,
          [`${dimension}/${subDimension}/${parameter}`]: data
        }));
      } catch (error) {
        console.error('Error fetching keywords:', error);
      }
    };
  
    const fetchAllKeywords = () => {
      if (dimension && subDimensionParameters) {
        Object.keys(subDimensionParameters).forEach(subDimension => {
          subDimensionParameters[subDimension].forEach(parameter => {
            fetchKeywords(dimension, subDimension, parameter);
          });
        });
      } else {
        console.error('Dimension or subDimensionParameters is undefined');
      }
    };
  
    fetchAllKeywords();
  }, [projectId, dimension, subDimensionParameters]);
  
  const handleSearch = async (dimension, subDimension, parameter) => {
    setSearching(true); // Set searching state to true when starting the search
  
    // Check if keywordsByParameter is defined and contains the necessary data
    if (!keywordsByParameter || !keywordsByParameter[`${dimension}/${subDimension}/${parameter}`]) {
      window.alert("Masukkan kata kunci dahulu.");
      setShowFolderWarning(true);
      setSearching(false);
      return;
    }
  
    const currentFolderPath = `docs/${dimension}/${subDimension}/${parameter}`;
  
    // Extract keywords and join them into a comma-separated string
    const keywordsArray = keywordsByParameter[`${dimension}/${subDimension}/${parameter}`];
    const keywordString = keywordsArray.map(keywordObj => keywordObj.keyword).join(',');
  
    try {
      // Construct the URL
      const url = `https://api1.rwiconsulting.tech/projects/${projectId}/search`;
      const params = {
        folder: currentFolderPath,
        ...(keywordString && { keyword: keywordString }) // Only add the keyword if it's available
      };
  
      const response = await axios.get(url, { params });
      const searchResults = response.data.results;
      const formattedResults = Object.entries(searchResults).reduce((acc, [filename, entries]) => {
        entries.forEach(({ page, keyword, context }) => {
          acc[keyword] = acc[keyword] || [];
          acc[keyword].push({ filename, page, context });
        });
        return acc;
      }, {});
  
      setSearchResults(formattedResults);
      setShowFolderWarning(false);
      setIsSearchModalOpen(true);
    } catch (error) {
      console.error("Error searching PDFs:", error);
    } finally {
      setSearching(false);
    }
  };
  

  const [filesByParameter, setFilesByParameter] = useState({});

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const parameters = Object.keys(subDimensionParameters).flatMap(subDimension =>
          subDimensionParameters[subDimension].map(parameter => `${dimension}/${subDimension}/${parameter}`)
        );
  
        const promises = parameters.map(parameter =>
          axios.get(`https://api1.rwiconsulting.tech/files/${projectId}?folder=docs/${parameter}`)
        );
  
        const responses = await Promise.all(promises);
  
        // Menyimpan file-file berdasarkan parameter
        const filesByParameter = {};
        responses.forEach((response, index) => {
          const parameter = parameters[index];
          filesByParameter[parameter] = response.data.files;
        });
        setFilesByParameter(filesByParameter);
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    };
  
    fetchFiles();
  }, [projectId, dimension, subDimensionParameters]);
  

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleSubmenu = () => {
    setIsSubmenuOpen(!isSubmenuOpen);
  };

  const hideSidebar = () => {
    const navbar = document.getElementById("navbar");
    const table = document.querySelector("table");
    const button = document.getElementById("navbartoggle");
    if (sidebarHidden) {
    navbar.style.left = "0px";
    table.style.marginLeft = "2rem";
    button.style.right = "-10px";
    button.style.marginLeft = "190px"; // Menambahkan margin left pada tombol hamburger
    button.innerHTML = `
    <svg width="31" height="31" viewBox="0 0 51 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 10H38.7879" stroke="white" stroke-width="5" stroke-linecap="round" />
        <path d="M12.0127 23.4699H38.8006" stroke="white" stroke-width="5" stroke-linecap="round" />
        <path d="M12.0127 36H38.8006" stroke="white" stroke-width="5" stroke-linecap="round" />
    </svg>
    `;
    } else {
    const navbarwidth = navbar.offsetWidth;
    navbar.style.left = "-" + navbarwidth + "px";
    table.style.marginLeft = "-10rem";
    button.style.right = "-20px";
    button.style.marginLeft = "0"; // Menghapus margin left pada tombol hamburger
    button.innerHTML = `
    <svg width="31" height="31" viewBox="0 0 51 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 10H38.7879" stroke="white" stroke-width="5" stroke-linecap="round" />
        <path d="M12.0127 23.4699H38.8006" stroke="white" stroke-width="5" stroke-linecap="round" />
        <path d="M12.0127 36H38.8006" stroke="white" stroke-width="5" stroke-linecap="round" />
    </svg>
    `;
    }

    setSidebarHidden(!sidebarHidden);
  };

  const [keywordsByParameter, setKeywordsByParameter] = useState({});

  const renderSubDimensionParameters = (subDimension) => {
    return subDimensionParameters[subDimension] && subDimensionParameters[subDimension].map(parameter => (
      <li key={parameter}>
        <div className="shadow-sm flex w-full justify-between mt-5 mb-4">
          <div className="flex justify-between gap-4">
            <div className="text-white text-lg ml-14 flex-1 w-80">{parameter}</div>
            <input className='w-12 h-12 bg-white rounded-md text-black text-center' type="text" />
          </div>
          <div className='flex-1 w-14 ml-6 max-h-[8rem] overflow-y-auto'>
            <button onClick={() => { 
              setSelectedDimension(dimension); // Set the selected dimension
              setSelectedSubDimension(subDimension); 
              setSelectedParameter(parameter); 
              setIsAddKeywordModalOpen(true); 
            }} className='cursor-pointer mr-2 mt-2'>
            <svg className="mr-3" width={20} height={22} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M29.75 12.75H21.25V4.25C21.25 1.90188 19.3471 0 17 0C14.6529 0 12.75 1.90188 12.75 4.25V12.75H4.25C1.90294 12.75 0 14.6519 0 17C0 19.3481 1.90294 21.25 4.25 21.25H12.75V29.75C12.75 32.0981 14.6529 34 17 34C19.3471 34 21.25 32.0981 21.25 29.75V21.25H29.75C32.0971 21.25 34 19.3481 34 17C34 14.6519 32.0971 12.75 29.75 12.75Z" fill="white" />
            </svg>
            </button>
            <button className='ml-2' onClick={() => exportKeywordsToTxt(dimension, subDimension, parameter)}>
            <svg width="26" height="28" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 2.5V17.5M15 17.5L11.25 13.75M15 17.5L18.75 13.75" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M23.75 6.25C23.75 3.75 21.75 1.75 19.25 1.75H9.75C7.25 1.75 5.25 3.75 5.25 6.25V22.75C5.25 25.25 7.25 27.25 9.75 27.25H19.25C21.75 27.25 23.75 25.25 23.75 22.75V6.25Z" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
            </button>
            <div className='flex flex-col'>
            <ul>
                {keywordsByParameter[`${dimension}/${subDimension}/${parameter}`] && keywordsByParameter[`${dimension}/${subDimension}/${parameter}`].map((keyword, index) => (
                  <li key={index} className="flex justify-between">
                  <span className='text-white '>{keyword.keyword}</span>
                  <button 
                    onClick={() => deleteKeyword(dimension, subDimension, parameter, index)}
                    className="text-red-500"
                  >
                    Delete
                  </button>
                </li>
                ))}
              </ul>
            </div>
          </div>
          <div className='text-white w-80 h-auto overflow-y-auto'>
            <ul className='text-xs text-nowrap'>
              {filesByParameter[`${dimension}/${subDimension}/${parameter}`] && filesByParameter[`${dimension}/${subDimension}/${parameter}`].map((file, index) => (
                <li key={index}>
                  <button onClick={() => handleDeleteFile(`docs/${dimension}/${subDimension}/${parameter}`, file)} className="transition-all duration-600 mr-2">
                  <svg className='hover:bg-red-500' width="14" height="15" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M19.7908 5.05686C18.1408 4.89289 16.4908 4.76991 14.8306 4.67768V4.66743L14.6052 3.33517C14.4515 2.39234 14.226 0.978088 11.8279 0.978088H9.14291C6.75505 0.978088 6.52959 2.33085 6.36562 3.32492L6.15041 4.63669C5.19733 4.69818 4.24425 4.75966 3.29117 4.8519L1.20054 5.05686C0.770117 5.09785 0.462672 5.47704 0.503664 5.89721C0.544657 6.31739 0.913591 6.62483 1.34401 6.58384L3.43464 6.37888C8.80472 5.84597 14.2158 6.05093 19.6473 6.59409H19.7293C20.1187 6.59409 20.4569 6.29689 20.4979 5.89721C20.5286 5.47704 20.2212 5.09785 19.7908 5.05686Z" fill="white"/>
               <path d="M17.905 8.03908C17.6591 7.78288 17.3209 7.6394 16.9724 7.6394H4.0188C3.67037 7.6394 3.32193 7.78288 3.08622 8.03908C2.85051 8.29529 2.71729 8.64373 2.73778 9.00241L3.37317 19.517C3.4859 21.0748 3.62937 23.0219 7.20599 23.0219H13.7853C17.3619 23.0219 17.5055 21.085 17.6181 19.517L18.2535 9.01266C18.274 8.64373 18.1407 8.29529 17.905 8.03908ZM12.1968 17.8876H8.78415C8.36403 17.8876 8.01559 17.5391 8.01559 17.119C8.01559 16.6988 8.36403 16.3504 8.78415 16.3504H12.1968C12.617 16.3504 12.9654 16.6988 12.9654 17.119C12.9654 17.5391 12.617 17.8876 12.1968 17.8876ZM13.0576 13.7883H7.93361C7.51343 13.7883 7.16499 13.4399 7.16499 13.0197C7.16499 12.5995 7.51343 12.2511 7.93361 12.2511H13.0576C13.4778 12.2511 13.8263 12.5995 13.8263 13.0197C13.8263 13.4399 13.4778 13.7883 13.0576 13.7883Z" fill="white"/>
             </svg>
                  </button>
                  <a href={`https://api1.rwiconsulting.tech/uploads/${file}`} target="_blank" rel="noopener noreferrer">
                    {file}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <button className='' onClick={() => handleSearch(dimension, subDimension, parameter)}>
          <svg width="29" height="29" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.9149 24.9006L36 36M28.6667 15.8333C28.6667 22.921 22.921 28.6667 15.8333 28.6667C8.74569 28.6667 3 22.921 3 15.8333C3 8.74569 8.74569 3 15.8333 3C22.921 3 28.6667 8.74569 28.6667 15.8333Z" stroke="white" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
          </button>
        </div>
      </li>
    ));
  };

  return (
    <div>
{isSearchModalOpen && (
  <div className='fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-70 z-20'>
    <div className="flex flex-col space-y-2 w-full h-full p-4 overflow-auto">
      <button
        className="hover:text-white hover:bg-gray-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center bg-gray-300 text-black"
        onClick={() => setIsSearchModalOpen(false)}
      >
        <svg
          aria-hidden="true"
          className="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
      <div className='flex-1 mt-3 overflow-y-auto text-white bg-gray-800 p-4 rounded-md'>
        {/* Close button */}
        
        {/* Content */}
        {searched && Object.keys(searchResults).length === 0 && (
          <div className="text-red-500 mt-">Kata kunci yang dicari tidak ditemukan.</div>
        )}
        {Object.keys(searchResults).map((keyword) => (
          <div key={keyword}>
            <h2 className="font-bold text-3xl mb-2 mt-2">File ditemukan untuk kata "{keyword}":</h2>
            <ul className="list-disc list-inside">
              {searchResults[keyword].map(({ filename, context, page }, index) => (
                <li key={index} className="text-blue-500 text-xl">
                  <a href={`https://api1.rwiconsulting.tech/uploads/${filename}`} target="_blank" rel="noopener noreferrer">
                    {filename}, halaman: {page}
                    <p className='text-gray-300 text-2xl'>
                      {context.split(new RegExp(`(${keyword})`, 'gi')).map((part, index) => (
                        index % 2 === 0 ? (
                          <span key={index}>{part}</span>
                        ) : (
                          <b key={index} className="text-white">{part}</b>
                        )
                      ))}
                    </p>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  </div>
)}


        <nav className="p-4" style={{backgroundColor: '#353A50'}}>
          <div className="flex items-center justify-between">
            {/* Hamburger menu */}
            <div className>
              <button onClick={hideSidebar} id="navbartoggle" className=" transition-all duration-500" style={{right: '-10px', marginLeft: '190px'}}>
                <svg width={31} height={31} viewBox="0 0 51 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 10H38.7879" stroke="white" strokeWidth={5} strokeLinecap="round" />
                  <path d="M12.0127 23.4699H38.8006" stroke="white" strokeWidth={5} strokeLinecap="round" />
                  <path d="M12.0127 36H38.8006" stroke="white" strokeWidth={5} strokeLinecap="round" />
                </svg>
              </button>
            </div>
            {/* Messages icon */}
            <div className="flex items-center space-x-6">
              <a href="#" className="text-white">
                <svg width={18} height={18} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.3929 0H10.6071C4.71429 0 0 4.74074 0 10.6667V30.8148C0 31.2889 0.235714 31.6444 0.707143 31.8815C0.825 32 1.06071 32 1.17857 32C1.41429 32 1.76786 31.8815 2.00357 31.763C6.12857 28.0889 11.4321 26.0741 16.8536 26.0741H22.3929C28.2857 26.0741 33 21.3333 33 15.4074V10.6667C33 4.74074 28.2857 0 22.3929 0ZM14.1429 16.5926H9.42857C8.72143 16.5926 8.25 16.1185 8.25 15.4074C8.25 14.6963 8.72143 14.2222 9.42857 14.2222H14.1429C14.85 14.2222 15.3214 14.6963 15.3214 15.4074C15.3214 16.1185 14.85 16.5926 14.1429 16.5926ZM20.0357 11.8519H9.42857C8.72143 11.8519 8.25 11.3778 8.25 10.6667C8.25 9.95556 8.72143 9.48148 9.42857 9.48148H20.0357C20.7429 9.48148 21.2143 9.95556 21.2143 10.6667C21.2143 11.3778 20.7429 11.8519 20.0357 11.8519Z" fill="white" />
                </svg>
              </a>
              {/* Notification icon */}
              <a href="#" className="text-white ">
                <svg className="mr-5" width={18} height={18} viewBox="0 0 27 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M24.063 25.4423H2.18261C1.37222 25.4423 0.663137 25.0183 0.257945 24.2762C-0.147247 23.5341 -0.0459489 22.6861 0.359243 21.944L1.47352 20.1418C3.29689 17.2796 4.20857 13.9933 4.20857 10.601C4.20857 6.67861 6.63972 3.07428 10.1852 1.80216C10.7929 0.636058 11.9072 0 13.1228 0C14.3384 0 15.4527 0.636058 16.0604 1.80216C19.6059 3.07428 22.037 6.67861 22.037 10.601C22.037 13.9933 22.9487 17.2796 24.7721 20.1418L25.8864 21.944C26.2915 22.6861 26.3928 23.5341 25.9877 24.2762C25.6838 25.0183 24.8734 25.4423 24.063 25.4423Z" fill="white" />
                  <path d="M8.75977 29.4471C9.26626 31.8853 11.2922 33.6875 13.7234 33.6875C16.1545 33.6875 18.1805 31.8853 18.687 29.4471H8.75977Z" fill="white" />
                </svg>
              </a>
               <div className="relative z-10 cursor-pointer">
      <div className="flex items-center space-x-10" onClick={toggleDropdown2}>
        {/* Profile icon */}
        <div className="flex items-center text-white">
          <div to="#" className="text-white mr-1">
            <img src="/assets/Group 88.png" alt="Profile" className="h-10 w-10" />
          </div>
          <span className="ml-2 text-sm mr-5">Admin RWI</span>
        </div>
      </div>
      {isOpen2 && (
        <ul className="absolute top-12 right-0 bg-gray-600 shadow-lg rounded-lg py-2 w-40">
          <li className="font-medium">
            <Link to ="/login" className="flex items-center transform transition-colors duration-200 border-r-4 border-transparent hover:border-red-600">
              <div className="px-5 mr-3 text-red-600">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
                </svg>
              </div>
              <div className="text-white">
                 Logout
              </div>
             
            </Link>
          </li>
        </ul>
      )}
    </div>
            </div>
          </div></nav>
        {/* Sidebar */}
        <div className="flex w-screen">
        <nav className="fixed left-0 top-0 w-48 h-screen transition-all duration-500 shadow-xl rounded-tr-3xl" id="navbar" style={{backgroundColor: '#353A50'}}>
            <div className="flex items-align justify-center mb-8 mt-8">
            <Link to ="/dashboard">
                <img src="/assets/Logo.png" alt="Logo" className="h-24 w-24" />
              </Link>
            </div>
            
            <div className="text-center">
        <label htmlFor="search-dropdown" className="mb-2 text-sm font-medium  sr-only text-white">
         
        </label>
        
        <button
        id="dropdownDefaultButton"
        onClick={toggleDropdown}
        className="text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
        type="button"
      >
        <svg className="absolute left-5" width="18" height="22" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 0C18.6169 0 24 5.35215 24 11.928C24 12.8864 23.8885 13.8308 23.6682 14.7345L23.5369 15.2706H16.5346C16.1958 16.201 15.5365 17.0205 14.5906 17.5201C13.992 17.833 13.32 18 12.6452 18C11.1021 18 9.69459 17.1594 8.97318 15.8053C8.89976 15.6663 8.82494 15.4853 8.75011 15.2706H0.463062L0.331762 14.7345C0.111525 13.8308 0 12.8864 0 11.928C0 5.35215 5.38306 0 12 0ZM14.1021 15.2706C14.6979 14.6574 14.8673 13.7158 14.4438 12.9215C13.9158 11.9336 9.3967 7.78265 9.3967 7.78265C9.3967 7.78265 10.3172 13.8308 10.8438 14.8187C10.9355 14.9914 11.0555 15.1359 11.184 15.2706H11.1727C11.1981 15.2973 11.2292 15.3155 11.2546 15.3394C11.3125 15.3941 11.3718 15.4446 11.4339 15.4909C11.4805 15.526 11.5299 15.5569 11.5779 15.5863C11.6428 15.6256 11.7078 15.6621 11.7769 15.6958C11.8306 15.7197 11.8842 15.7421 11.9407 15.7632C12.0085 15.787 12.0776 15.8095 12.1496 15.8263C12.2083 15.8416 12.2677 15.8543 12.3275 15.8642C12.3995 15.8754 12.4701 15.8824 12.5421 15.8852C12.5774 15.8867 12.6113 15.8951 12.6452 15.8951C12.6692 15.8951 12.6932 15.8867 12.7172 15.8867C12.8202 15.8838 12.9219 15.8698 13.0235 15.8502C13.0701 15.8403 13.1181 15.8361 13.1647 15.8235C13.3115 15.7842 13.4555 15.7337 13.5939 15.6593H13.5981V15.6579C13.7944 15.5555 13.9567 15.4194 14.1021 15.2706ZM18.6212 7.49498L20.0301 6.0945L18.5336 4.6056L17.1233 6.00608L18.6212 7.49498ZM10.9412 4.33336H13.0588V2.35192H10.9412V4.33336ZM5.37883 7.49499L6.8767 6.0061L5.46776 4.60561L3.96989 6.0945L5.37883 7.49499Z" fill="white"/>
</svg>

        Dashboard RMI
        <svg
          className={`w-2.5 h-2.5 ms-3 transform transition-transform absolute right-6 ${
            isOpen ? 'rotate-180' : ''
          }`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>
        {isOpen && (
          <div className="z-10  divide-y " id="dropdown">
            <ul className="py-2 text-sm text-gray-200" aria-labelledby="dropdown-button">
            <Link to ="/product">
                <li>
                <button type="button" className="inline-flex w-full px-4 py-2 hover:bg-gray-600 hover:text-white"
                 ><svg className="mt-1 mr-5 ml-3" width="12" height="12" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path fill-rule="evenodd" clip-rule="evenodd" d="M16.625 7.125H11.875V2.375C11.875 1.06281 10.8116 0 9.5 0C8.18841 0 7.125 1.06281 7.125 2.375V7.125H2.375C1.06341 7.125 0 8.18781 0 9.5C0 10.8122 1.06341 11.875 2.375 11.875H7.125V16.625C7.125 17.9372 8.18841 19 9.5 19C10.8116 19 11.875 17.9372 11.875 16.625V11.875H16.625C17.9366 11.875 19 10.8122 19 9.5C19 8.18781 17.9366 7.125 16.625 7.125Z" fill="white"/>
                 </svg>
                 
                  Create a Project</button>
              </li>
              </Link>
              <li>
              <button type="button" className="inline-flex w-full px-4 py-2 hover:bg-gray-600 hover:text-white"
                 onClick={toggleSubmenu}>
                  <svg className="mt-1 mr-5 ml-3" width="12" height="12" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M4.1618 18.5992C4.07439 18.5992 3.99091 18.5669 3.92712 18.5047L0.095092 14.6624C-0.0316973 14.5372 -0.0316973 14.3301 0.095092 14.2017L3.92712 10.3547C4.02084 10.2626 4.1618 10.2326 4.28465 10.2846C4.4075 10.3319 4.48625 10.4539 4.48625 10.587V12.9661H14.9877C15.2783 12.9661 15.5153 12.7291 15.5153 12.44V9.7625C15.5153 9.67508 15.5492 9.59239 15.6091 9.53018L18.1732 6.95581C18.2661 6.86288 18.4095 6.83295 18.5307 6.88414C18.6544 6.93612 18.7339 7.0574 18.7339 7.18812V13.0086C18.7339 15.0876 17.6353 16.1854 15.5579 16.1854H4.48704V18.2739C4.48704 18.4054 4.40829 18.5243 4.28544 18.5732C4.24606 18.5897 4.20118 18.5992 4.1618 18.5992Z" fill="white"/>
                 <path d="M0.922142 11.7446C0.878829 11.7446 0.837091 11.7352 0.796928 11.7186C0.674076 11.669 0.59375 11.5509 0.59375 11.417V5.59731C0.59375 3.51749 1.68997 2.42128 3.76978 2.42128H14.8453V0.327284C14.8453 0.197345 14.9249 0.0776428 15.0469 0.0240921C15.1714 -0.0239461 15.3108 -0.000320755 15.4053 0.0965431L19.2357 3.93566C19.3633 4.06324 19.3633 4.27193 19.2357 4.39872L15.4053 8.24335C15.3115 8.33864 15.1722 8.36777 15.0477 8.31501C14.9257 8.2654 14.8461 8.14491 14.8461 8.01103V5.63747H4.33758C4.04856 5.63747 3.81467 5.87373 3.81467 6.16432V8.84264C3.81467 8.92927 3.78081 9.01038 3.71702 9.07575L1.15131 11.647C1.09067 11.71 1.00877 11.7446 0.922142 11.7446Z" fill="white"/>
                 </svg>
                 
                  On Going
                  <svg
                  className={`w-2.5 h-2.5 ms-3 transform transition-transform absolute right-6 mt-1.5 ${
                    isSubmenuOpen ? 'rotate-180' : ''
                  }`}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg></button>
                {isSubmenuOpen && (
                <ul className="py-2 text-sm text-gray-200">
                   <Link to={`/projects/${projectId}/ProjectDetail/${productId}/`}>
 <li>
                <button type="button" className="inline-flex w-full px-4 py-2 hover:bg-gray-600 hover:text-white"
                 ><PiUploadSimpleBold className='mr-2 ml-6' />
                 Upload Document</button>
              </li>
                   </Link>
                 
                   <Link to ={`/files/${projectId}/${productId}`}>
                              <li>
                <button type="button" className="inline-flex w-full px-4 py-2 hover:bg-gray-600 hover:text-white"
                 ><RxEnter className='mr-2 ml-6' />
                 Insert Dokument</button>
              </li>
               </Link>
              
                </ul>
              )}
              </li>
              <Link to ="/Closure">
                <li>
                <button type="button" className="inline-flex w-full px-4 py-2 hover:bg-gray-600 hover:text-white"
                ><svg className="mt-1 mr-5 ml-3" width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8 12L10.6635 15L16 9" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                
                  Closure</button>
              </li>
              </Link>
              
            </ul>
          </div>
        )}
                <button
        id="dropdownDefaultButton"
        className="text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
        type="button"
      >
        <svg className="absolute left-5" idth="18" height="18" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.4894 8.31528C23.4043 7.90617 22.9787 7.6607 22.5532 7.6607C21.5319 7.82435 20.5106 7.33341 20 6.43337C19.4894 5.61515 19.5745 4.46964 20.1702 3.73325C20.4255 3.40596 20.4255 2.91503 20.0851 2.66956C18.7234 1.44223 17.1064 0.54219 15.3191 0.0512584C14.8936 -0.0305636 14.4681 0.13308 14.2979 0.54219C13.8723 1.44223 12.9362 2.01499 11.9149 2.01499C10.8936 2.01499 9.95745 1.44223 9.53191 0.54219C9.3617 0.13308 8.93617 -0.112386 8.51064 0.0512584C6.7234 0.54219 5.10638 1.44223 3.74468 2.75138C3.40426 2.99685 3.40426 3.48778 3.65957 3.81507C4.25532 4.63329 4.34043 5.69697 3.82979 6.51519C3.31915 7.33341 2.29787 7.82435 1.2766 7.74252C0.851064 7.6607 0.425532 7.98799 0.340426 8.3971C0.0851064 9.21532 0 10.1154 0 11.0154C0 11.9154 0.0851064 12.8155 0.340426 13.7155C0.425532 14.1246 0.851064 14.3701 1.2766 14.2883C2.29787 14.1246 3.31915 14.6156 3.82979 15.5156C4.34043 16.3338 4.25532 17.4793 3.65957 18.2157C3.40426 18.543 3.40426 19.034 3.74468 19.2794C5.10638 20.5068 6.80851 21.4886 8.59574 21.9795C9.02128 22.0614 9.44681 21.8977 9.61702 21.4886C10.0426 20.5886 10.9787 20.0158 12 20.0158C13.0213 20.0158 13.9574 20.5886 14.383 21.4886C14.5532 21.8159 14.8085 21.9795 15.1489 21.9795C15.234 21.9795 15.3191 21.9795 15.4043 21.9795C17.1915 21.4886 18.8936 20.5068 20.2553 19.2794C20.5957 18.9521 20.5957 18.543 20.3404 18.2157C19.7447 17.3975 19.6596 16.3338 20.1702 15.5156C20.6809 14.6974 21.7021 14.2065 22.7234 14.2883C23.1489 14.3701 23.5745 14.0428 23.6596 13.6337C23.9149 12.7337 24 11.8336 24 10.9336C24 10.0335 23.7447 9.21532 23.4894 8.31528ZM11.9149 14.2883C10.0426 14.2883 8.51064 12.8155 8.51064 11.0154C8.51064 9.21532 10.0426 7.74252 11.9149 7.74252C13.7872 7.74252 15.3191 9.21532 15.3191 11.0154C15.3191 12.8155 13.7872 14.2883 11.9149 14.2883Z" fill="white"/>
</svg>


        Setting
        <svg
          className={`w-2.5 h-2.5 ms-3 transform transition-transform absolute right-6 ${
            isOpen ? 'rotate-180' : ''
          }`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>

      </div>
      
           
          </nav>
          {searching && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-slate-600 bg-opacity-70">
          <div className="flex flex-col space-y-2 items-center bg-slate-600 p-4 rounded-md">
            <h2 className="text-lg font-semibold text-white">Searching...</h2>
          </div>
        </div>
      )}

          <div className="mx-auto">

            <div className='absolute top-20 right-10 flex space-x-4 '>
            <Link to={`/projects/${projectId}/ProjectDetail/${productId}/rescan`}>
                            <svg className='mt-3' width="31" height="30" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="51" height="50" rx="10" fill="#353A50"/>
<path d="M10.8334 20.5831L9.80218 21.6143L8.771 20.5831L9.80218 19.5519L10.8334 20.5831ZM35.625 32.2498C35.625 33.0552 34.9722 33.7081 34.1667 33.7081C33.3613 33.7081 32.7084 33.0552 32.7084 32.2498H35.625ZM17.0939 28.906L9.80218 21.6143L11.8646 19.5519L19.1563 26.8436L17.0939 28.906ZM9.80218 19.5519L17.0939 12.2603L19.1563 14.3227L11.8646 21.6143L9.80218 19.5519ZM10.8334 19.1248H25.4167V22.0415H10.8334V19.1248ZM35.625 29.3331V32.2498H32.7084V29.3331H35.625ZM25.4167 19.1248C31.0546 19.1248 35.625 23.6952 35.625 29.3331H32.7084C32.7084 25.3061 29.4438 22.0415 25.4167 22.0415V19.1248Z" fill="white"/>
</svg>
              </Link>


                 <button onClick={handlePreviousClick} class="flex items-center justify-center px-3 mt-3 h-8 ms-0 leading-tight border border-e-0  rounded-s-lg  hover:bg-gray-800 border-gray-700 text-white bg-gray-700 hover:text-white">
        <span class="sr-only">Previous</span>
        <svg class="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4"/>
        </svg>
      </button>
          <div className="m-3 px-8 py-1 rounded-lg text-white text-md shadow-md hover:shadow-lg" style={{ backgroundColor: '#353A50' }}>
        Dimensi {currentDimensionIndex + 1}
      </div>
<button onClick={handleNextClick} class="flex items-center justify-center mt-3 px-3 h-8 leading-tight border  rounded-e-lg  hover:bg-gray-800 border-gray-700 text-white bg-gray-700 hover:text-white">
<span class="sr-only">Next</span>
        <svg class="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
        </svg>
</button>

            </div>
            <table className="mt-3 rounded-lg  transition-all duration-500 scrollable-container w-full">
              <tbody>
                <tr>
                  <td>
                  <div class="flex flex-col mt-20 ml-20  justify-normal w-full">
  <div class="shadow-sm bg-gray-700 flex w-full justify-between rounded-[30px]">
    <div class="flex  justify-between gap-5">
      <div class="bg-red-500  w-[23px]  h-[42px] rounded-[30px_0px_0px_30px]"></div>
      <div class="text-white text-lg my-auto">{dimension}</div>
    </div>
    <svg className='pr-3 mt-1 ' width="80" height="33" viewBox="0 0 125 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M111.001 8.55734L101.564 18.4966C100.753 19.2628 100.739 20.5188 101.534 21.3013C102.319 22.0847 103.6 22.0985 104.401 21.3321L112.524 12.7878L120.46 21.5048C121.245 22.2881 122.526 22.3019 123.328 21.5356C124.139 20.7703 124.153 19.5143 123.358 18.7308L114.137 8.59104C113.711 8.16683 113.139 7.97602 112.575 8.00446C112.011 7.96389 111.436 8.14237 111.001 8.55734Z" fill="white"/>
<path d="M33 16.5C33 25.6127 25.6127 33 16.5 33C7.3873 33 0 25.6127 0 16.5C0 7.3873 7.3873 0 16.5 0C25.6127 0 33 7.3873 33 16.5ZM6.46589 16.5C6.46589 22.0417 10.9583 26.5341 16.5 26.5341C22.0417 26.5341 26.5341 22.0417 26.5341 16.5C26.5341 10.9583 22.0417 6.46589 16.5 6.46589C10.9583 6.46589 6.46589 10.9583 6.46589 16.5Z" fill="#D9D9D9"/>
<path d="M16.5 3.23294C16.5 1.44744 15.0389 -0.0319785 13.2876 0.315664C10.1078 0.946863 7.15878 2.5067 4.83274 4.83274C1.73839 7.92709 3.82568e-07 12.1239 0 16.5C-3.82568e-07 20.8761 1.73839 25.0729 4.83274 28.1673C7.15877 30.4933 10.1078 32.0531 13.2876 32.6843C15.0389 33.032 16.5 31.5526 16.5 29.7671C16.5 27.9816 15.0162 26.5832 13.3226 26.0178C11.8601 25.5295 10.5156 24.706 9.40481 23.5952C7.52305 21.7134 6.46589 19.1612 6.46589 16.5C6.46589 13.8388 7.52305 11.2866 9.40481 9.40481C10.5156 8.294 11.8601 7.47053 13.3226 6.98223C15.0162 6.4168 16.5 5.01845 16.5 3.23294Z" fill="#EE4150"/>
<path d="M51.304 20.544H53.8V24H43.096L43.072 20.544H46.912V10.728H43.096V7.176H51.304V20.544ZM63.6687 4.56H67.2687L59.4927 26.64H55.8927L63.6687 4.56ZM80.3839 20.544V24H67.3759V21.096L73.5919 14.784C74.0879 14.288 74.4879 13.792 74.7919 13.296C75.1119 12.784 75.2719 12.352 75.2719 12C75.2719 11.552 75.0959 11.2 74.7439 10.944C74.3919 10.688 73.9039 10.56 73.2799 10.56C72.5919 10.56 71.8319 10.752 70.9999 11.136C70.1679 11.52 69.3439 12.04 68.5279 12.696L66.8719 9.48C69.2239 7.8 71.5759 6.96 73.9279 6.96C75.0639 6.96 76.0799 7.144 76.9759 7.512C77.8719 7.88 78.5759 8.4 79.0879 9.072C79.5999 9.728 79.8559 10.488 79.8559 11.352C79.8559 12.216 79.5679 13.12 78.9919 14.064C78.4159 15.008 77.6159 15.992 76.5919 17.016L72.9679 20.544H80.3839Z" fill="white"/>
</svg>

  </div>
  <div>
  <ul>
          {subDimensions.map((subDimension, folderIndex) => (
            <li key={subDimension}>
              <div className="shadow-sm bg-gray-700 flex w-full justify-between">
                <div className="flex justify-between">
                  <div className="text-white text-lg my-auto pl-11">{subDimension}</div>
                </div>
              </div>
              <ul>
                {renderSubDimensionParameters(subDimension)}
              </ul>
            </li>
          ))}
        </ul>

        {isAddKeywordModalOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-8 rounded shadow-lg w-96">
          <div className='flex flex-col space-y-2'>
            <button
              className="hover:text-white hover:bg-gray-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center bg-gray-300 text-black"
              onClick={() => setIsAddKeywordModalOpen(false)}
            >
              <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
              </svg>
            </button>
            <h2 className="text-xl font-bold mb-4">Tambah Keyword</h2>
            <input
              type="file"
              accept=".txt"
              className="border rounded px-3 py-2 w-full mb-4"
              onChange={handleTxtFileUpload}
            />
     <ul className="max-h-[10rem] overflow-y-auto">
  {uploadedKeywords.map((keywordObj, index) => (
    <li key={index} className="flex items-center">
      <input
        type="checkbox"
        id={`keyword-${index}`}
        name={`keyword-${index}`}
        className="mr-2"
        checked={keywordObj.checked}
        onChange={(e) => {
          const checked = e.target.checked;
          setUploadedKeywords((prev) => {
            const newKeywords = [...prev];
            newKeywords[index].checked = checked;
            return newKeywords;
          });
          updateKeywordChecked(keywordObj.id, checked);
        }}
      />
      <label htmlFor={`keyword-${index}`} className="text-gray-700">
        {keywordObj.keyword}
      </label>
      <button onClick={() => handleDeleteKeyword(keywordObj.id)} className="ml-2 text-red-500">
        Delete
      </button>
    </li>
  ))}
</ul>


            <input
              type="text"
              className="border rounded px-3 py-2 w-full mb-4"
              placeholder="Masukkan keyword"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onKeyDown={handleEnterKey}
            />
           <div>
              <label htmlFor="modal-dimension-select" className="block text-gray-700">Pilih Dimensi:</label>
              <select id="modal-dimension-select" className="border rounded px-3 py-2 w-full mb-4" value={modalDimension} onChange={handleDimensionChange}>
                <option value="">Pilih Dimensi</option>
                {dimensionFolders.map((folder) => (
                  <option key={folder} value={folder}>{folder}</option>
                ))}
              </select>
            </div>
            {modalDimension && (
              <div>
                <label htmlFor="modal-subdimension-select" className="block text-gray-700">Pilih SubDimensi:</label>
                <select id="modal-subdimension-select" className="border rounded px-3 py-2 w-full mb-4" value={modalSubDimension} onChange={handleSubDimensionChange}>
                  <option value="">Pilih SubDimensi</option>
                  {modalSubDimensions.map((subDim) => (
                    <option key={subDim} value={subDim}>{subDim}</option>
                  ))}
                </select>
              </div>
            )}
            {modalSubDimension && (
              <div>
                <label htmlFor="modal-parameter-select" className="block text-gray-700">Pilih Parameter:</label>
                <select id="modal-parameter-select" className="border rounded px-3 py-2 w-full mb-4" value={modalParameter} onChange={(e) => setModalParameter(e.target.value)}>
                  <option value="">Pilih Parameter</option>
                  {modalSubDimensionParameters[modalSubDimension] && modalSubDimensionParameters[modalSubDimension].map((param) => (
                    <option key={param} value={param}>{param}</option>
                  ))}
                </select>
              </div>
            )}
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={handleAddUploadedKeywords}
      >
        Masukkan Keyword ke Insert
      </button>
          </div>
        </div>
      </div>
    )}
    </div>
</div>
                  </td>
                </tr>
                <tr>
              <td>
                <nav aria-label="Page navigation example" className="flex justify-center mt-10 ml-20">
                  <ul className="flex items-center -space-x-px h-8 text-sm gap-3">
                  <li>
      <button onClick={handlePreviousClick} className="flex items-center justify-center px-3 h-8 leading-tight text-white   rounded-md " style={{backgroundColor: '#353A50'}}>
        <span class="sr-only">Previous</span>
        <svg class="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4"/>
        </svg>
      </button>
    </li>
                    <li>
                      <a href="#" aria-current="page" className="flex items-center justify-center px-3 h-8 leading-tight text-white    rounded-md  " style={{backgroundColor: '#6D758A'}}>{currentDimensionIndex + 1}</a>
                    </li>
                    <li>
                      <button onClick={handleNextClick} className="flex items-center justify-center px-3 h-8 leading-tight text-white   rounded-md " style={{backgroundColor: '#353A50'}}>
                        <span className="sr-only">Next</span>
                        <svg className="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m1 9 4-4-4-4" />
                        </svg>
                      </button>
                    </li>
                  </ul>
                </nav>
              </td>
            </tr>
              </tbody>
            

            </table>
          </div>
        </div>
      </div>
  );
};


export default KajiPDF;
