import React, { useState, useEffect } from "react";
import axios from "axios";

const CompletedProductsPage = () => {
  const [completedProducts, setCompletedProducts] = useState([]);

  useEffect(() => {
    const fetchCompletedProducts = async () => {
      try {
        const response = await axios.get("https://api1.rwiconsulting.tech/completed-products");
        setCompletedProducts(response.data);
      } catch (error) {
        console.error("Error fetching completed products:", error);
      }
    };

    fetchCompletedProducts();
  }, []);

  return (
    <div>
      <h1>Completed Products</h1>
      <ul>
        {completedProducts.map((product, index) => (
          <li key={index}>{product}</li>
        ))}
      </ul>
    </div>
  );
};

export default CompletedProductsPage;
