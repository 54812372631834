// Register.js

import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";


const Register = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [warning, setWarning] = useState(""); 
  const [successMessage, setSuccessMessage] = useState(""); 

  const navigate = useNavigate();

  const registerUser = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://api1.rwiconsulting.tech/register", {
        username,
        email,
        password,
      });

      setWarning(""); // Clear any previous warnings
      setSuccessMessage("Registration successful. Redirecting to login page...");

      // Redirect to login page after 3 seconds
      setTimeout(() => {
        navigate("/login");
      }, 2500);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setWarning(error.response.data.msg);
      } else {
        console.log(error);
      }
    }
  };

  // Reset the warning and success message when the username input changes
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setWarning("");
    setSuccessMessage("");
  };

  // Reset the warning and success message when the email input changes
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setWarning("");
    setSuccessMessage("");
  };
  return (
    <>
  {/* Background image taking up 60% of the screen width */}
  <img
    src="/assets/bg.png"
    alt="Background"
    className="absolute top-0 left-0 w-3/5 h-screen"
  />
  {/* Union.png image at the top right corner, slightly larger */}
  <img
    src="/assets/Union.png"
    alt="Union"
    className="absolute top-0 right-0 w-3/5 h-full"
  />
  {/* Rectangle 6 image covering the entire screen */}
  <img
    src="/assets/Rectangle 6.png"
    alt="Background"
    className="absolute inset-0 w-full h-full object-cover"
  />
  {/* Logo at the top left corner */}
  <img
    src="/assets/Logo.png"
    alt="Logo"
    className="absolute top-0 left-0 mt-3 max-w-24 max-h-24"
  />
  {/* Navigation links at the top left corner */}
  <div className="absolute top-0 left-32 mt-8 ml-3">
      <Link to="/" className="mr-20 text-white hover:border-red-500 hover:border-b-2">Join</Link>
      <Link to="/login" className="mr-20 text-white hover:border-red-500 hover:border-b-2">Login</Link>
      <Link to="" className="text-white hover:border-red-500 hover:border-b-2">About us</Link>
    </div>
  {/* Text in the center-left with automatic text line breaks */}
  <div className="absolute top-1/2 left-1/5 transform -translate-y-1/2 ml-20 text-white text-5xl whitespace-pre-line">
    Contributing to
    <br /> Agile &amp; Resilient <br /> Enterprises
  </div>
  {/* Registration form on the Union.png background */}
  <div className="absolute top-0 right-0 w-2/5 h-full flex items-center justify-center">
    {/* Your registration form elements go here */}
    <div className="w-full md:mt-0 sm:max-w-md xl:p-0 mr-10">
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
        <h1 className="text-xl font-bold leading-tight tracking-tight  md:text-2xl text-white">
          Create New Account
        </h1>
        <form onSubmit={registerUser}
          className="space-y-4 md:space-y-6"
          method="post"
          
        >
          <div>
            <input
              type="username"
              name="username"
              id="username"
              className="shadow-lg shadow-red-500 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-full focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
              value={username}
              onChange={handleUsernameChange}
              placeholder="Username"
              required=""
            />
          </div>
          <div>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
              className="shadow-lg shadow-red-500 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-full focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
              required=""
            />
          </div>
          <div>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="shadow-lg shadow-red-500 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-full focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
              required=""
            />
          </div>
          <div className="mb-4">
        <div className="text-red-500">{warning}</div>
        {successMessage && (
          <div className="text-green-500">{successMessage}</div>
        )}
      </div>
          <div className="flex items-center justify-end">
            <p className="text-xs font-light text-white">
              Already have an account? Click{" "}
              <Link to
                ="/login"
                className="font-medium text-red-600 hover:underline"
              >
                here
              </Link>
            </p>
          </div>
          <button
            type="submit"
            className="w-full text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center"
          >
            Create New Account
          </button>
        </form>
      </div>
    </div>
  </div>
</>

  );
};

export default Register;

