import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Dashboard from './Dashboard';

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [sidebarHidden, setSidebarHidden] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteProductId, setDeleteProductId] = useState(null);
  const [isOpen1, setIsOpen1] = useState(false);

  const toggleDropdown1 = () => {
    setIsOpen1(!isOpen1);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const hideSidebar = () => {
    const navbar = document.getElementById("navbar");
    const table = document.querySelector("table");
    const button = document.getElementById("navbartoggle");
    if (sidebarHidden) {
      navbar.style.left = "0px";
      table.style.marginLeft = "2rem";
      button.style.right = "-10px";
      button.style.marginLeft = "190px"; // Menambahkan margin left pada tombol hamburger
      button.innerHTML = `
        <svg width="31" height="31" viewBox="0 0 51 47" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 10H38.7879" stroke="white" stroke-width="5" stroke-linecap="round" />
          <path d="M12.0127 23.4699H38.8006" stroke="white" stroke-width="5" stroke-linecap="round" />
          <path d="M12.0127 36H38.8006" stroke="white" stroke-width="5" stroke-linecap="round" />
        </svg>
      `;
    } else {
      const navbarwidth = navbar.offsetWidth;
      navbar.style.left = "-" + navbarwidth + "px";
      table.style.marginLeft = "-10rem";
      button.style.right = "-20px";
      button.style.marginLeft = "0"; // Menghapus margin left pada tombol hamburger
      button.innerHTML = `
        <svg width="31" height="31" viewBox="0 0 51 47" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 10H38.7879" stroke="white" stroke-width="5" stroke-linecap="round" />
          <path d="M12.0127 23.4699H38.8006" stroke="white" stroke-width="5" stroke-linecap="round" />
          <path d="M12.0127 36H38.8006" stroke="white" stroke-width="5" stroke-linecap="round" />
        </svg>
      `;
    }

    setSidebarHidden(!sidebarHidden);
  };

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get("https://api1.rwiconsulting.tech/products");
      setProducts(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteProduct = async () => {
    try {
      await axios.delete(`https://api1.rwiconsulting.tech/products/${deleteProductId}`);
      setDeleteProductId(null); // Setelah hapus, reset ID produk yang akan dihapus
      getProducts();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    
    <div className="overflow-x-hidden">
{deleteProductId && (
  <div id="deleteModal" className="fixed inset-0 z-10 overflow-y-auto bg-gray-800 bg-opacity-75 flex justify-center items-center">
    <div className="relative p-4 w-full max-w-md h-full md:h-auto">
      <div className="relative p-4 text-center rounded-lg shadow bg-gray-700 sm:p-5">
        <button type="button" className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-600 hover:text-white" onClick={() => setDeleteProductId(null)}>
          <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
          <span className="sr-only">Close modal</span>
        </button>
        <svg className="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
        <p className="mb-4 text-gray-300">Are you sure you want to delete this item?</p>
        <div className="flex justify-center items-center space-x-4">
          <button onClick={() => setDeleteProductId(null)} type="button" className="py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10 bg-gray-700 text-gray-300 border-gray-500 hover:text-white hover:bg-gray-600 focus:ring-gray-600">
            No, cancel
          </button>
          <button onClick={deleteProduct} type="submit" className="py-2 px-3 text-sm font-medium text-center text-white rounded-lg focus:ring-4 focus:outline-none bg-red-500 hover:bg-red-600 focus:ring-red-900">
            Yes, I'm sure
          </button>
        </div>
      </div>
    </div>
  </div>
)}

        <nav className="p-4" style={{backgroundColor: '#353A50'}}>
          <div className="flex items-center justify-between">
            {/* Hamburger menu */}
            <div className>
              <button onClick={hideSidebar} id="navbartoggle" className=" transition-all duration-500" style={{right: '-10px', marginLeft: '190px'}}>
                <svg width={31} height={31} viewBox="0 0 51 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 10H38.7879" stroke="white" strokeWidth={5} strokeLinecap="round" />
                  <path d="M12.0127 23.4699H38.8006" stroke="white" strokeWidth={5} strokeLinecap="round" />
                  <path d="M12.0127 36H38.8006" stroke="white" strokeWidth={5} strokeLinecap="round" />
                </svg>
              </button>
            </div>
            {/* Messages icon */}
            <div className="flex items-center space-x-6">
              <div href="#" className="text-white">
                <svg width={18} height={18} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.3929 0H10.6071C4.71429 0 0 4.74074 0 10.6667V30.8148C0 31.2889 0.235714 31.6444 0.707143 31.8815C0.825 32 1.06071 32 1.17857 32C1.41429 32 1.76786 31.8815 2.00357 31.763C6.12857 28.0889 11.4321 26.0741 16.8536 26.0741H22.3929C28.2857 26.0741 33 21.3333 33 15.4074V10.6667C33 4.74074 28.2857 0 22.3929 0ZM14.1429 16.5926H9.42857C8.72143 16.5926 8.25 16.1185 8.25 15.4074C8.25 14.6963 8.72143 14.2222 9.42857 14.2222H14.1429C14.85 14.2222 15.3214 14.6963 15.3214 15.4074C15.3214 16.1185 14.85 16.5926 14.1429 16.5926ZM20.0357 11.8519H9.42857C8.72143 11.8519 8.25 11.3778 8.25 10.6667C8.25 9.95556 8.72143 9.48148 9.42857 9.48148H20.0357C20.7429 9.48148 21.2143 9.95556 21.2143 10.6667C21.2143 11.3778 20.7429 11.8519 20.0357 11.8519Z" fill="white" />
                </svg>
              </div>
              {/* Notification icon */}
              <div href="#" className="text-white ">
                <svg className="mr-5" width={18} height={18} viewBox="0 0 27 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M24.063 25.4423H2.18261C1.37222 25.4423 0.663137 25.0183 0.257945 24.2762C-0.147247 23.5341 -0.0459489 22.6861 0.359243 21.944L1.47352 20.1418C3.29689 17.2796 4.20857 13.9933 4.20857 10.601C4.20857 6.67861 6.63972 3.07428 10.1852 1.80216C10.7929 0.636058 11.9072 0 13.1228 0C14.3384 0 15.4527 0.636058 16.0604 1.80216C19.6059 3.07428 22.037 6.67861 22.037 10.601C22.037 13.9933 22.9487 17.2796 24.7721 20.1418L25.8864 21.944C26.2915 22.6861 26.3928 23.5341 25.9877 24.2762C25.6838 25.0183 24.8734 25.4423 24.063 25.4423Z" fill="white" />
                  <path d="M8.75977 29.4471C9.26626 31.8853 11.2922 33.6875 13.7234 33.6875C16.1545 33.6875 18.1805 31.8853 18.687 29.4471H8.75977Z" fill="white" />
                </svg>
              </div>
               <div className="relative z-50 cursor-pointer">
      <div className="flex items-center space-x-10" onClick={toggleDropdown1}>
        {/* Profile icon */}
        <div className="flex items-center text-white">
          <div to="#" className="text-white mr-1">
            <img src="/assets/Group 88.png" alt="Profile" className="h-10 w-10" />
          </div>
          <span className="ml-2 text-sm mr-5">Admin RWI</span>
        </div>
      </div>
      {isOpen1 && (
        <ul className="absolute top-12 right-0 bg-gray-600 shadow-lg rounded-lg py-2 w-40">
          <li className="font-medium">
            <Link to ="/login" className="flex items-center transform transition-colors duration-200 border-r-4 border-transparent hover:border-red-600">
              <div className="px-5 mr-3 text-red-600">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
                </svg>
              </div>
              <div className="text-white">
                 Logout
              </div>
             
            </Link>
          </li>
        </ul>
      )}
    </div>
            </div>
          </div></nav>
        {/* Sidebar */}
        <div className="flex w-screen">
          <nav className="fixed left-0 top-0 w-48 h-screen transition-all duration-500 shadow-xl rounded-tr-3xl" id="navbar" style={{backgroundColor: '#353A50'}}>
            <div className="flex items-align justify-center mb-8 mt-8">
            <Link to ="/dashboard">
                <img src="/assets/Logo.png" alt="Logo" className="h-24 w-24" />
              </Link>
            </div>
            
            <div className="text-center">
        <label htmlFor="search-dropdown" className="mb-2 text-sm font-medium  sr-only text-white">
         
        </label>
        
        <button
        id="dropdownDefaultButton"
        onClick={toggleDropdown}
        className="text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
        type="button"
      >
        <svg className="absolute left-5" width="18" height="22" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 0C18.6169 0 24 5.35215 24 11.928C24 12.8864 23.8885 13.8308 23.6682 14.7345L23.5369 15.2706H16.5346C16.1958 16.201 15.5365 17.0205 14.5906 17.5201C13.992 17.833 13.32 18 12.6452 18C11.1021 18 9.69459 17.1594 8.97318 15.8053C8.89976 15.6663 8.82494 15.4853 8.75011 15.2706H0.463062L0.331762 14.7345C0.111525 13.8308 0 12.8864 0 11.928C0 5.35215 5.38306 0 12 0ZM14.1021 15.2706C14.6979 14.6574 14.8673 13.7158 14.4438 12.9215C13.9158 11.9336 9.3967 7.78265 9.3967 7.78265C9.3967 7.78265 10.3172 13.8308 10.8438 14.8187C10.9355 14.9914 11.0555 15.1359 11.184 15.2706H11.1727C11.1981 15.2973 11.2292 15.3155 11.2546 15.3394C11.3125 15.3941 11.3718 15.4446 11.4339 15.4909C11.4805 15.526 11.5299 15.5569 11.5779 15.5863C11.6428 15.6256 11.7078 15.6621 11.7769 15.6958C11.8306 15.7197 11.8842 15.7421 11.9407 15.7632C12.0085 15.787 12.0776 15.8095 12.1496 15.8263C12.2083 15.8416 12.2677 15.8543 12.3275 15.8642C12.3995 15.8754 12.4701 15.8824 12.5421 15.8852C12.5774 15.8867 12.6113 15.8951 12.6452 15.8951C12.6692 15.8951 12.6932 15.8867 12.7172 15.8867C12.8202 15.8838 12.9219 15.8698 13.0235 15.8502C13.0701 15.8403 13.1181 15.8361 13.1647 15.8235C13.3115 15.7842 13.4555 15.7337 13.5939 15.6593H13.5981V15.6579C13.7944 15.5555 13.9567 15.4194 14.1021 15.2706ZM18.6212 7.49498L20.0301 6.0945L18.5336 4.6056L17.1233 6.00608L18.6212 7.49498ZM10.9412 4.33336H13.0588V2.35192H10.9412V4.33336ZM5.37883 7.49499L6.8767 6.0061L5.46776 4.60561L3.96989 6.0945L5.37883 7.49499Z" fill="white"/>
</svg>

        Dashboard RMI
        <svg
          className={`w-2.5 h-2.5 ms-3 transform transition-transform absolute right-6 ${
            isOpen ? 'rotate-180' : ''
          }`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>
        {isOpen && (
          <div className="z-10  divide-y " id="dropdown">
            <ul className="py-2 text-sm text-gray-200" aria-labelledby="dropdown-button">
              <Link to ="/product">
                <li>
                <button type="button" className="inline-flex w-full px-4 py-2 hover:bg-gray-600 hover:text-white"
                 ><svg className="mt-1 mr-5 ml-3" width="12" height="12" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path fill-rule="evenodd" clip-rule="evenodd" d="M16.625 7.125H11.875V2.375C11.875 1.06281 10.8116 0 9.5 0C8.18841 0 7.125 1.06281 7.125 2.375V7.125H2.375C1.06341 7.125 0 8.18781 0 9.5C0 10.8122 1.06341 11.875 2.375 11.875H7.125V16.625C7.125 17.9372 8.18841 19 9.5 19C10.8116 19 11.875 17.9372 11.875 16.625V11.875H16.625C17.9366 11.875 19 10.8122 19 9.5C19 8.18781 17.9366 7.125 16.625 7.125Z" fill="white"/>
                 </svg>
                 
                  Create a Project</button>
              </li>
              </Link>
              
              <li>
                <button type="button" className="inline-flex w-full px-4 py-2 hover:bg-gray-600 hover:text-white"
                 ><svg className="mt-1 mr-5 ml-3" width="12" height="12" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M4.1618 18.5992C4.07439 18.5992 3.99091 18.5669 3.92712 18.5047L0.095092 14.6624C-0.0316973 14.5372 -0.0316973 14.3301 0.095092 14.2017L3.92712 10.3547C4.02084 10.2626 4.1618 10.2326 4.28465 10.2846C4.4075 10.3319 4.48625 10.4539 4.48625 10.587V12.9661H14.9877C15.2783 12.9661 15.5153 12.7291 15.5153 12.44V9.7625C15.5153 9.67508 15.5492 9.59239 15.6091 9.53018L18.1732 6.95581C18.2661 6.86288 18.4095 6.83295 18.5307 6.88414C18.6544 6.93612 18.7339 7.0574 18.7339 7.18812V13.0086C18.7339 15.0876 17.6353 16.1854 15.5579 16.1854H4.48704V18.2739C4.48704 18.4054 4.40829 18.5243 4.28544 18.5732C4.24606 18.5897 4.20118 18.5992 4.1618 18.5992Z" fill="white"/>
                 <path d="M0.922142 11.7446C0.878829 11.7446 0.837091 11.7352 0.796928 11.7186C0.674076 11.669 0.59375 11.5509 0.59375 11.417V5.59731C0.59375 3.51749 1.68997 2.42128 3.76978 2.42128H14.8453V0.327284C14.8453 0.197345 14.9249 0.0776428 15.0469 0.0240921C15.1714 -0.0239461 15.3108 -0.000320755 15.4053 0.0965431L19.2357 3.93566C19.3633 4.06324 19.3633 4.27193 19.2357 4.39872L15.4053 8.24335C15.3115 8.33864 15.1722 8.36777 15.0477 8.31501C14.9257 8.2654 14.8461 8.14491 14.8461 8.01103V5.63747H4.33758C4.04856 5.63747 3.81467 5.87373 3.81467 6.16432V8.84264C3.81467 8.92927 3.78081 9.01038 3.71702 9.07575L1.15131 11.647C1.09067 11.71 1.00877 11.7446 0.922142 11.7446Z" fill="white"/>
                 </svg>
                 
                  On Going</button>
              </li>
              <li>
                <button type="button" className="inline-flex w-full px-4 py-2 hover:bg-gray-600 hover:text-white"
                ><svg className="mt-1 mr-5 ml-3" width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8 12L10.6635 15L16 9" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                
                  Closure</button>
              </li>
            </ul>
          </div>
        )}
                <button
        id="dropdownDefaultButton"
        className="text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
        type="button"
      >
        <svg className="absolute left-5" idth="18" height="18" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.4894 8.31528C23.4043 7.90617 22.9787 7.6607 22.5532 7.6607C21.5319 7.82435 20.5106 7.33341 20 6.43337C19.4894 5.61515 19.5745 4.46964 20.1702 3.73325C20.4255 3.40596 20.4255 2.91503 20.0851 2.66956C18.7234 1.44223 17.1064 0.54219 15.3191 0.0512584C14.8936 -0.0305636 14.4681 0.13308 14.2979 0.54219C13.8723 1.44223 12.9362 2.01499 11.9149 2.01499C10.8936 2.01499 9.95745 1.44223 9.53191 0.54219C9.3617 0.13308 8.93617 -0.112386 8.51064 0.0512584C6.7234 0.54219 5.10638 1.44223 3.74468 2.75138C3.40426 2.99685 3.40426 3.48778 3.65957 3.81507C4.25532 4.63329 4.34043 5.69697 3.82979 6.51519C3.31915 7.33341 2.29787 7.82435 1.2766 7.74252C0.851064 7.6607 0.425532 7.98799 0.340426 8.3971C0.0851064 9.21532 0 10.1154 0 11.0154C0 11.9154 0.0851064 12.8155 0.340426 13.7155C0.425532 14.1246 0.851064 14.3701 1.2766 14.2883C2.29787 14.1246 3.31915 14.6156 3.82979 15.5156C4.34043 16.3338 4.25532 17.4793 3.65957 18.2157C3.40426 18.543 3.40426 19.034 3.74468 19.2794C5.10638 20.5068 6.80851 21.4886 8.59574 21.9795C9.02128 22.0614 9.44681 21.8977 9.61702 21.4886C10.0426 20.5886 10.9787 20.0158 12 20.0158C13.0213 20.0158 13.9574 20.5886 14.383 21.4886C14.5532 21.8159 14.8085 21.9795 15.1489 21.9795C15.234 21.9795 15.3191 21.9795 15.4043 21.9795C17.1915 21.4886 18.8936 20.5068 20.2553 19.2794C20.5957 18.9521 20.5957 18.543 20.3404 18.2157C19.7447 17.3975 19.6596 16.3338 20.1702 15.5156C20.6809 14.6974 21.7021 14.2065 22.7234 14.2883C23.1489 14.3701 23.5745 14.0428 23.6596 13.6337C23.9149 12.7337 24 11.8336 24 10.9336C24 10.0335 23.7447 9.21532 23.4894 8.31528ZM11.9149 14.2883C10.0426 14.2883 8.51064 12.8155 8.51064 11.0154C8.51064 9.21532 10.0426 7.74252 11.9149 7.74252C13.7872 7.74252 15.3191 9.21532 15.3191 11.0154C15.3191 12.8155 13.7872 14.2883 11.9149 14.2883Z" fill="white"/>
</svg>


        Setting
        <svg
          className={`w-2.5 h-2.5 ms-3 transform transition-transform absolute right-6 ${
            isOpen ? 'rotate-180' : ''
          }`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button> 
      </div>
          </nav>
          {/* Content */}
          <div className="ml-44">
            <table className="table m-3 rounded-lg  transition-all duration-500 scrollable-container">
              <tbody><tr>
                
                  <td className="flex flex-wrap justify-center  ">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4 ml-6 mr-8">
                      {products.map((product) => (
                        <div class="max-w-sm rounded-2xl overflow-hidden drop-shadow-md hover:shadow-xl hover:scale-105 transition-all duration-450" style={{ backgroundColor: "#353A50" }} key={product.id}>
                           <Link to={`/products/${product.id}`}>
                            <img className="w-full" src="assets/bg-card/Group 93.png" alt = {product.name}/>
                            <div className="mx-auto w-24 h-24 relative -mt-28 border-1 rounded-full overflow-hidden">
                              <img className="object-cover h-full w-full rounded-[50%]" src={product.url} alt={product.name}/>
                            </div>
                          <div className="px-4 py-4">
                            <div className="font-bold text-xl mb-7 text-white mt-5">{product.name}</div>
                            
                        </div>
                          </Link>
                          <div className="flex justify-end mr-5 mb-3">
                          <button onClick={() => setDeleteProductId(product.id)} type="button" className="py-2 px-3 bg-neutral-500 w-max text-white rounded-xl transition-all duration-600 hover:bg-gray-600">
  <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.7908 5.05686C18.1408 4.89289 16.4908 4.76991 14.8306 4.67768V4.66743L14.6052 3.33517C14.4515 2.39234 14.226 0.978088 11.8279 0.978088H9.14291C6.75505 0.978088 6.52959 2.33085 6.36562 3.32492L6.15041 4.63669C5.19733 4.69818 4.24425 4.75966 3.29117 4.8519L1.20054 5.05686C0.770117 5.09785 0.462672 5.47704 0.503664 5.89721C0.544657 6.31739 0.913591 6.62483 1.34401 6.58384L3.43464 6.37888C8.80472 5.84597 14.2158 6.05093 19.6473 6.59409H19.7293C20.1187 6.59409 20.4569 6.29689 20.4979 5.89721C20.5286 5.47704 20.2212 5.09785 19.7908 5.05686Z" fill="white"/>
    <path d="M17.905 8.03908C17.6591 7.78288 17.3209 7.6394 16.9724 7.6394H4.0188C3.67037 7.6394 3.32193 7.78288 3.08622 8.03908C2.85051 8.29529 2.71729 8.64373 2.73778 9.00241L3.37317 19.517C3.4859 21.0748 3.62937 23.0219 7.20599 23.0219H13.7853C17.3619 23.0219 17.5055 21.085 17.6181 19.517L18.2535 9.01266C18.274 8.64373 18.1407 8.29529 17.905 8.03908ZM12.1968 17.8876H8.78415C8.36403 17.8876 8.01559 17.5391 8.01559 17.119C8.01559 16.6988 8.36403 16.3504 8.78415 16.3504H12.1968C12.617 16.3504 12.9654 16.6988 12.9654 17.119C12.9654 17.5391 12.617 17.8876 12.1968 17.8876ZM13.0576 13.7883H7.93361C7.51343 13.7883 7.16499 13.4399 7.16499 13.0197C7.16499 12.5995 7.51343 12.2511 7.93361 12.2511H13.0576C13.4778 12.2511 13.8263 12.5995 13.8263 13.0197C13.8263 13.4399 13.4778 13.7883 13.0576 13.7883Z" fill="white"/>
  </svg>
</button>

                          </div>
                         
                      </div>
                      ))}
                    </div>
                  </td>
                </tr>
              </tbody></table></div>
          <Link to ="/add" className="flex items-center bg-red-500 m-3 px-6 py-4 rounded-3xl text-white text-lg shadow-md hover:bg-red-600 hover:shadow-lg fixed bottom-12 right-8">
            {/* Plus Icon */}
            <svg className="mr-3" width={20} height={20} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M29.75 12.75H21.25V4.25C21.25 1.90188 19.3471 0 17 0C14.6529 0 12.75 1.90188 12.75 4.25V12.75H4.25C1.90294 12.75 0 14.6519 0 17C0 19.3481 1.90294 21.25 4.25 21.25H12.75V29.75C12.75 32.0981 14.6529 34 17 34C19.3471 34 21.25 32.0981 21.25 29.75V21.25H29.75C32.0971 21.25 34 19.3481 34 17C34 14.6519 32.0971 12.75 29.75 12.75Z" fill="white" />
            </svg>
            New Client
          </Link>
        </div>
      </div>
  );
};

export default ProductList;
