import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

const EditProduct = () => {
  const [title, setTitle] = useState("");
  const [productType, setProductType] = useState("");
  const [description, setDescription] = useState("");
  const [personInChargeName1, setPersonInChargeName1] = useState("");
  const [personInChargeName2, setPersonInChargeName2] = useState("");
  const [personInChargeContact1, setPersonInChargeContact1] = useState("");
  const [personInChargeContact2, setPersonInChargeContact2] = useState("");
  const [address, setAddress] = useState("");
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getProductById();
  }, []);

  const getProductById = async () => {
    try {
      const response = await axios.get(`https://api1.rwiconsulting.tech/products/${id}`);
      setTitle(response.data.name);
      setPreview(response.data.url);
      setProductType(response.data.productType);
      setDescription(response.data.description);
      setPersonInChargeName1(response.data.personInChargeName1);
      setPersonInChargeName2(response.data.personInChargeName2);
      setPersonInChargeContact1(response.data.personInChargeContact1);
      setPersonInChargeContact2(response.data.personInChargeContact2);
      setAddress(response.data.address);
    } catch (error) {
      console.log(error);
    }
  };

  const loadImage = (e) => {
    const image = e.target.files[0];
    setFile(image);
    setPreview(URL.createObjectURL(image));
  };

  const updateProduct = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("productType", productType);
      formData.append("description", description);
      formData.append("personInChargeName1", personInChargeName1);
      formData.append("personInChargeName2", personInChargeName2);
      formData.append("personInChargeContact1", personInChargeContact1);
      formData.append("personInChargeContact2", personInChargeContact2);
      formData.append("address", address);
      if (file) {
        formData.append("file", file);
      }
      await axios.patch(`https://api1.rwiconsulting.tech/products/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      navigate("/product");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-75'>
          <div className='mt-3 w-[600px]  max-h-[35rem] overflow-y-auto text-white bg-gray-800 p-4 rounded-md'>
        <form onSubmit={updateProduct}>
        <button
              className="absolute top-0 right-0 m-4 text-white hover:text-red-500"
             
            >
              Close
            </button>
          <div className="mb-4">
            <label className="block text-white text-sm font-bold mb-2">Company Name</label>
            <input
              type="text"
               className="border border-gray-300 p-2 w-full text-black"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Company Name"
            />
          </div>
          
          <div className="mb-4">
            <label className="block text-white text-sm font-bold mb-2">Address</label>
            <input
              type="text"
               className="border border-gray-300 p-2 w-full text-black"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Address"
            />
          </div>

          <div className="mb-4">
            <label className="block text-white text-sm font-bold mb-2">Company Type</label>
            <input
              type="text"
               className="border border-gray-300 p-2 w-full text-black"
              value={productType}
              onChange={(e) => setProductType(e.target.value)}
              placeholder="Company Type"
            />
          </div>

          <div className="mb-4">
            <label className="block text-white text-sm font-bold mb-2">Description</label>
            <textarea
               className="border border-gray-300 p-2 w-full text-black"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Description"
            />
          </div>

          <div className="mb-4">
            <label className="block text-white text-sm font-bold mb-2">Person In Charge Name 1</label>
            <input
              type="text"
               className="border border-gray-300 p-2 w-full text-black"
              value={personInChargeName1}
              onChange={(e) => setPersonInChargeName1(e.target.value)}
              placeholder="Person In Charge Name 1"
            />
          </div>
          
          <div className="mb-4">
            <label className="block text-white text-sm font-bold mb-2">Person In Charge Name 2</label>
            <input
              type="text"
               className="border border-gray-300 p-2 w-full text-black"
              value={personInChargeName2}
              onChange={(e) => setPersonInChargeName2(e.target.value)}
              placeholder="Person In Charge Name 2"
            />
          </div>

          <div className="mb-4">
            <label className="block text-white text-sm font-bold mb-2">Person In Charge Contact 1</label>
            <input
              type="text"
               className="border border-gray-300 p-2 w-full text-black"
              value={personInChargeContact1}
              onChange={(e) => setPersonInChargeContact1(e.target.value)}
              placeholder="Person In Charge Contact 1"
            />
          </div>

          <div className="mb-4">
            <label className="block text-white text-sm font-bold mb-2">Person In Charge Contact 2</label>
            <input
              type="text"
               className="border border-gray-300 p-2 w-full text-black"
              value={personInChargeContact2}
              onChange={(e) => setPersonInChargeContact2(e.target.value)}
              placeholder="Person In Charge Contact 2"
            />
          </div>

          <div className="mb-4">
            <label className="block text-white text-sm font-bold mb-2">Image</label>
            <div className="control">
              <div className="file">
                <label className="file-label">
                  <input
                    type="file"
                    className="file-input"
                    onChange={loadImage}
                  />
                </label>
              </div>
            </div>
          </div>

          {preview && (
            <figure className="mb-4">
              <img src={preview} alt="Preview Image" className="w-32 h-32 object-cover rounded" />
            </figure>
          )}

          <div className="field">
            <div className="control">
              <button type="submit" className="bg-green-500 text-white p-2 rounded hover:bg-green-600">
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProduct;
