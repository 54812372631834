import React, { useContext, useState  } from 'react';
import { BrowserRouter, Routes, Route, Navigate  } from "react-router-dom";
import Register from "./components/Register";
import Login from "./components/Login";
import ProductList from "./components/ProductList";
import AddProduct from "./components/AddProduct";
import EditProduct from "./components/EditProduct";
import Dashboard from "./components/Dashboard";
import DetailProduct from "./components/DetailProduct"
import ProductProject from "./components/CreateProject"
import ProductDocument from "./components/ProjectDokument"
import UploadPdf from "./components/PdfUploadPage"
import ScanPDF from "./components/PercentagePage"
import RescanPDF from "./components/RescanPDF"
import KajiPDF from "./components/KajiPDF";
import Closure from "./components/Closure.js"
import { KeywordProvider } from './KeywordContext';

const AuthContext = React.createContext();

const ProtectedRoute = ({ element, ...rest }) => {
  const authContext = React.useContext(AuthContext);

  if (!authContext.isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return React.cloneElement(element, { ...rest });
};

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const setAuthStatus = (status) => {
    setIsAuthenticated(status);
  };

  const authContextValue = {
    isAuthenticated,
    setAuthStatus,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      <KeywordProvider>        

     <BrowserRouter>
        <Routes>
           <Route path="/projects/:projectId/ProjectDetail/:productId/kajiPDF/:dimension" element={<KajiPDF />} />
          <Route path="/projects/:projectId/ProjectDetail/:productId/rescan"  element={<RescanPDF />} />

          <Route
            path="/projects/:projectId/ProjectDetail/:productId/percentage/"
             element={<ScanPDF />}
          />
          <Route
            path="/files/:projectId/:productId"
             element={<UploadPdf />} 
          />
          <Route path="/" element={<Register />} />
          <Route path="/login" element={<Login setAuthStatus={setAuthStatus} />} />
          <Route
            path="/dashboard"
             element={<Dashboard />}
          />
<Route
  path="/projects/:projectId/ProjectDetail/:productId"
   element={<ProductDocument />} 
/>
          <Route
            path="/product"
             element={<ProductList />} 
          />
           <Route
            path="/Closure"
             element={<Closure />} 
          />
          <Route
            path="/add"
             element={<AddProduct />} 
          />
          <Route
            path="products/:id/edit"
             element={<EditProduct />} 
          />
          <Route
            path="/products/:id"
             element={<DetailProduct />} 
          />
          <Route
            path="/products/:id/CreateProject"
             element={<ProductProject />} 
          />
        </Routes>    
        </BrowserRouter> 
      </KeywordProvider>

    </AuthContext.Provider>

  );
}

export default App;
