import React, { createContext, useState, useContext } from 'react';

// Buat konteks
const KeywordContext = createContext();

// Buat provider untuk konteks
export const KeywordProvider = ({ children }) => {
  const [keywordLists, setKeywordLists] = useState({});

  return (
    <KeywordContext.Provider value={{ keywordLists, setKeywordLists }}>
      {children}
    </KeywordContext.Provider>
  );
};

// Buat custom hook untuk menggunakan konteks
export const useKeywordContext = () => {
  return useContext(KeywordContext);
};
